@import 'variables';

* {
  outline: none;
}

table {
  /* highlight the hovered row */
  &.selection-table tbody {
    tr:hover {
      background-color: $gray-100;
    }

    .selection-link:hover {
      color: $dark-blue;
      cursor: pointer;
    }
  }

  .actions {
    cursor: default;
    .action:not(:last-child) {
      margin-right: $spacer / 4;
    }
  }
}

i {
  &.neutral {
    color: $standard-blue;
  }
  &.good {
    color: $green;
  }
  &.warn {
    color: $yellow;
  }
  &.error {
    color: $orange;
  }
  &.unimportant {
    color: $gray-400;
  }

  &.action {
    color: lighten($dark-blue, 10);
    padding: rem(4);
    border-radius: rem(4);

    &:hover:not(.disabled) {
      background-color: lighten($dark-blue, 43);
      cursor: pointer;
    }

    &.disabled {
      color: $gray-300;
    }
  }
}

.full-height {
  height: 100%;
}

/* toastr color overrides */
.toast-success {
  background-color: $green;
}
.toast-error {
  background-color: $red;
}
.toast-info {
  background-color: $dark-blue;
}
.toast-warning {
  background-color: $yellow;
}
