@import './functions.scss';

$standard-blue: #54b0cb;
$dark-blue: #456c9b;
$green: #6fa163;
$yellow: #f6af0b;
$red: #e84e25;
$min-contrast-ratio: 3;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-550: #8d959d;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$primary: $dark-blue;
$secondary: $standard-blue;
$body-color: $gray-600;
$light: $gray-300;
$text-muted: $gray-550;

$h1-font-size: rem(28);
$h2-font-size: rem(24);
$h3-font-size: rem(19);

$input-btn-padding-y: 0.1875rem;
$input-btn-padding-x: 0.375rem;
$input-color: $gray-600;
$input-placeholder-color: $gray-500;

$grid-gutter-width: 12px;
$border-color: $gray-200;

// table
$table-cell-padding: 0.625rem;

$banner-height: rem(60);

$form-switch-color: $secondary;
$form-switch-focus-color: $secondary;
$form-check-input-focus-box-shadow: $secondary;
$form-check-input-checked-bg-color: $secondary;

@import '../../../node_modules/bootstrap/scss/_functions';
@import '../../../node_modules/bootstrap/scss/_variables';
