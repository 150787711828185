@import 'variables';
@import 'functions';
@import 'utils';

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: $gray-200;
  font-family: Lato, Arial, sans-serif;
  overflow-x: hidden;
}

.header {
  display: flex;
  background-color: $white;
  position: relative;
  z-index: 2;

  .header-container {
    display: flex;
    flex: 1;
    max-width: 1600px;
    margin: 0 auto;

    .title {
      flex: 1;
      padding: rem(10) 0 0.4rem;
    }

    button:last-child {
      margin-right: rem(14);
    }
  }

  h2 {
    @extend .truncate;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0 rem(14) rem(6);
    color: $gray-600;
    max-width: 100%;
  }

  h6 {
    padding: 0 rem(14);
  }

  .unimportant {
    @extend .truncate;
    color: $gray-500;
  }
}

.viewport {
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$banner-height});
  overflow-y: auto;

  .content {
    flex: 1;
  }
}

.content {
  padding: rem(12);
  padding-bottom: $card-margin;
  max-width: 1600px;
}
