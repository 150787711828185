.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grow {
  display: flex;
  flex-grow: 1;
}
