$card-margin: $spacer;

.card {
  vertical-align: top;
  color: $gray-600;
  margin-bottom: $card-margin;
  position: relative;

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: map-get($spacers, 2);
  }

  .card-foot {
    color: $gray-500;
    border-top: rem(1) solid $gray-200;
  }
}

.individual-card {
  &:hover {
    cursor: pointer;
    background-color: $gray-100;
  }

  h3 {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.individual-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: map-get($spacers, 2);

  .card-actions {
    white-space: nowrap;

    i {
      margin-left: map-get($spacers, 2);
    }
  }
}

.card-full-height {
  min-height: 100%;
  margin-bottom: $card-margin;
}

.full-height-with-aggs {
  height: calc(100% - 112px);
}

.card-centered {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: $card-margin;
}

.card-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-500;
  cursor: pointer;

  &:hover {
    color: $dark-blue;
    background-color: $gray-100;
  }

  .card-button-title {
    font-weight: bold;
    text-align: center;
    margin-top: 0;
    margin-bottom: $spacer;
  }

  .card-button-icon {
    width: 100%;
    text-align: center;
    font-size: rem(36);
  }
}

.actions button:not(:last-child) {
  margin-right: map-get($spacers, 2);
}
