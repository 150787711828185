@import 'variables';

.form-group {
  margin-bottom: 1rem;
}

form.was-validated .form-control {
  &.ng-valid {
    border-color: $green;
    padding-right: calc(1.5em + 0.375rem);
    background-image: $form-feedback-icon-valid;
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.09375rem);
    background-size: calc(0.75em + 0.1875rem) calc(0.75em + 0.1875rem);

    &:focus {
      border-color: $green;
      box-shadow: 0 0 0 0.2rem rgba(111, 161, 99, 0.25);
    }
  }

  &.ng-invalid {
    border-color: $red;
    padding-right: calc(1.5em + 0.375rem);
    background-image: $form-feedback-icon-invalid;
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.09375rem);
    background-size: calc(0.75em + 0.1875rem) calc(0.75em + 0.1875rem);

    &:focus {
      border-color: $red;
      box-shadow: 0 0 0 0.2rem rgba(232, 78, 37, 0.25);
    }
  }

  &.ng-invalid ~ .invalid-feedback {
    display: block;
  }
}
