@import 'variables';

ngx-slider.ngx-slider {
  .ngx-slider-bar {
    background: $gray-300;
    height: rem(2);
  }
  .ngx-slider-selection {
    background: $standard-blue;
  }

  .ngx-slider-pointer {
    width: rem(8);
    height: rem(16);
    top: auto; /* to remove the default positioning */
    bottom: 0;
    background-color: $standard-blue;
    border-top-left-radius: rem(3);
    border-top-right-radius: rem(3);
  }

  .ngx-slider-pointer:after {
    display: none;
  }

  .ngx-slider-bubble {
    bottom: 1.4rem;
  }

  .ngx-slider-limit {
    font-weight: bold;
    color: $standard-blue;
  }

  .ngx-slider-tick {
    width: rem(1);
    height: rem(10);
    margin-left: rem(4);
    border-radius: 0;
    background: $gray-300;
    top: rem(-1);
  }

  .ngx-slider-tick.ngx-slider-selected {
    background: $standard-blue;
  }
}
