@use '@angular/material' as mat;
@import 'variables';

$sc-mat-primary: (
  50: #e7f2f8,
  100: #c4dff0,
  200: #a2cce6,
  300: #85b8da,
  400: #71a9d3,
  500: #456c9b,
  // #629bcc
  600: #598dbf,
  700: #4e7cad,
  800: #456c9b,
  900: #364f7a,
  A100: #c4dff0,
  A200: #a2cce6,
  A400: #71a9d3,
  A700: #4e7cad,
  contrast: (
    50: $body-color,
    100: $body-color,
    200: $body-color,
    300: $body-color,
    400: $body-color,
    500: $light,
    600: $light,
    700: $light,
    800: $light,
    900: $light,
    A100: $body-color,
    A200: $body-color,
    A400: $light,
    A700: $light,
  ),
);
@include mat.all-component-typographies();
@include mat.core();
$dark-primary-text: mat.get-contrast-color-from-palette(mat.$gray-palette, 50);
$light-primary-text: mat.get-contrast-color-from-palette(mat.$gray-palette, 900);
$mat-app-primary: mat.define-palette($sc-mat-primary);
$mat-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$mat-app-warn: mat.define-palette(mat.$red-palette);
$mat-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $mat-app-primary,
      accent: $mat-app-accent,
      warn: $mat-app-warn,
    ),
  )
);
@include mat.all-component-themes($mat-app-theme);

// Generated from https://materialtheme.arcsine.dev
$mobile-primary: (
  main: #3d315b,
  lighter: #c5c1ce,
  darker: #271f40,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$mobile-accent: (
  main: #000000,
  lighter: #b3b3b3,
  darker: #000000,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$mobile-warn: (
  main: #ba1200,
  lighter: #eab8b3,
  darker: #a30a00,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
// Alternate theme to match the styling of the mobile app
$mobile-theme-primary: mat.define-palette($mobile-primary, main, lighter, darker);
$mobile-theme-accent: mat.define-palette($mobile-accent, main, lighter, darker);
$mobile-theme-warn: mat.define-palette($mobile-warn, main, lighter, darker);
$mobile-theme: mat.define-light-theme(
  (
    color: (
      primary: $mobile-theme-primary,
      accent: $mobile-theme-accent,
      warn: $mobile-theme-warn,
    ),
  )
);
.mobile-theme {
  @include mat.all-component-colors($mobile-theme);
}
